import Head from "next/head"
import styles from "../styles/Home.module.css"
import type { NextPage } from "next"

const Home: NextPage = () => {
	return (
		<div className={ styles.container }>
			<Head>
				<title>ClassCloud</title>
				<meta name="description" content="" />
				{/* <link rel="icon" href="/favicon.ico" /> */ }
			</Head>

			<main className={ styles.main }>
				<h1 className={ styles.title }>
					ようこそ ClassCloudへ!
				</h1>
			</main>

			<footer className={ styles.footer }>
				{/* <a
					href="https://vercel.com?utm_source=create-next-app&utm_medium=default-template&utm_campaign=create-next-app"
					target="_blank"
					rel="noopener noreferrer"
				>
					Powered by{" "}
					<span className={styles.logo}>
						<Image src="/vercel.svg" alt="Vercel Logo" width={72} height={16} />
					</span>
				</a> */}
			</footer>
		</div>
	)
}

export default Home
